<template>
  <div v-if="jobDetails" class="overflow-x-hidden">
    <router-view />
    <div class="w-full top-0 fixed z-50">
      <div class="inner-bg absolute top-0 w-full"></div>
      <div class="top-bg absolute grid grid-cols-3 bg-theme-1 px-2 w-full">
        <div class="col-start-1 col-span-1">
          <v-icon
              @click="goBack"
              right
              dark
              class="h-full"
          >
            mdi-arrow-left
          </v-icon>
        </div>
        <div class="col-start-2">
          <h1 class="font-montserrat-regular text-white text-center h-full flex items-center justify-center whitespace-nowrap">Detalle de Orden</h1>
        </div>
      </div>
    </div>
    <div class="mt-32">
      <div class="mt-10 mx-5">
        <h1 class="font-montserrat-bold w-full text-center font-bold text-2xl">{{ jobDetails.fraud_key }}</h1>
      </div>
      <div class="mt-8 flex justify-center w-full">
        <img class="w-52 h-52 rounded-lg object-contain" src="../assets/img/placeholder.jpg" alt="">
      </div>
      <div class="mt-10 mx-5 mb-10">
        <div class="bg-theme-5 w-full m-h-10 flex gap-x-5 justify-between rounded-lg p-2">
          <span class="font-bold">Fusionadora:</span>
          <span>{{ jobDetails.work_order }}</span>
        </div>
        <div class="bg-theme-5 w-full m-h-10 flex gap-x-5 justify-between rounded-lg p-2 mt-3">
          <span class="font-bold">Tiempo:</span>
          <span>{{ jobDetails.record_time }}</span>
        </div>
        <div class="bg-theme-5 w-full m-h-10 flex gap-x-5 justify-between rounded-lg p-2 mt-3">
          <span class="font-bold">Geo:</span>
          <span>{{ jobDetails.lat }} / {{ jobDetails.lon }}</span>
        </div>
        <div class="bg-theme-5 w-full m-h-10 flex gap-x-5 justify-between rounded-lg p-2 mt-3">
          <span class="font-bold">Resultado:</span>
          <span v-if="jobDetails.operation_ok === true">OK</span>
          <span v-if="jobDetails.operation_ok === false">KO</span>
        </div>
        <div class="bg-theme-5 w-full m-h-10 flex gap-x-5 justify-between rounded-lg p-2 mt-3">
          <span class="font-bold">Descripcion:</span>
          <span>{{ jobDetails.result }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import redirect from "@/mixins/redirect";

import {mapGetters} from "vuex";

export default {
  name: "OrderDetails",
  // mixins: [redirect],
  mounted() {
    if (this.$session.get("username")) {
      return true
    } else {
      this.$router.push("/")
    }
  },
  beforeMount() {
    this.$store.dispatch("loginUser/userJobDetailsAction", this.$route.params.id)
  },
  computed: {
    ...mapGetters({
      jobDetails: "loginUser/userJobDetailsGetter"
    })
  },
  methods: {
    goBack() {
      return this.$router.go(-1)
    }
  }
}
</script>
<style lang="scss" scoped>
.top-bg {
  height: 90px;
  z-index: 2;
  border-bottom-left-radius: 4rem;
  border-bottom-right-radius: 4rem;
}
.inner-bg {
  height: 95px;
  background: #F48B8B;
  z-index: 1;
  border-bottom-left-radius: 4rem;
  border-bottom-right-radius: 4rem;
}
</style>
